document.addEventListener('turbolinks:load', function () {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-in'); // Tailwind animation class
          observer.unobserve(entry.target); // Stop observing once the animation is triggered
        }
      });
    }, { threshold: 0.1 }); // Trigger when 10% of the element is visible

    const cards = document.querySelectorAll('.slide-in-trigger');
    cards.forEach(card => {
      observer.observe(card);
    });

    const headerFaq = document.getElementById("header-faq");
    if (headerFaq !== null) {
      const faqHeaders = document.querySelectorAll(".faq-header");

      faqHeaders.forEach((header) => {
        header.addEventListener("click", function () {
          const answer = this.nextElementSibling;

          if (answer.classList.contains("max-h-0")) {
            answer.classList.remove("max-h-0");
            answer.classList.add("max-h-[800px]", "py-3"); // Adjust height and padding for a smooth open
            this.querySelector(".toggle-icon").textContent = "−"; // Set icon to minus
          } else {
            answer.classList.add("max-h-0");
            answer.classList.remove("max-h-[800px]", "py-3");
            this.querySelector(".toggle-icon").textContent = "+"; // Set icon to plus
          }
        });
      });

      const scrollToTopBtn = document.getElementById("scrollToTop");
      const faqSection = document.getElementById("faqSection");


      window.addEventListener("scroll", () => {
        // Get the current scroll position relative to #faqSection and #header-faq
        const faqRect = faqSection.getBoundingClientRect();
        const headerRect = headerFaq.getBoundingClientRect();

        // Show the button only when the user is within the FAQ section and not at #header-faq
        if (faqRect.top < window.innerHeight && faqRect.bottom > 0 && headerRect.bottom < 0) {
          scrollToTopBtn.classList.remove("hidden");
        } else {
          scrollToTopBtn.classList.add("hidden");
        }
      });

      // Scroll back to the top of the background image when the button is clicked
      scrollToTopBtn.addEventListener("click", () => {
        headerFaq.scrollIntoView({
          behavior: "smooth",
        });
      });
    }

  });
